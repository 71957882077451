<template>
  <div>
    <Loader v-if="userWatchlist.length == 0" />
    <div class="dashboard__container--body" >

      <UserTable :groupUsers="sortedList" v-if="sortedList && sortedList.length >= 1"/>

    </div>  
  </div>
</template>

<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UserTable from '@/components/UserTable.vue'
// import ExportService from "@/services/ExportService"
import router from '@/router'

export default {
  name: 'myUserWatchlist',
  // data() {
  //   return {
  //     columns: [
  //       {
  //         label: 'Link',
  //         field: 'link',
  //       },
  //       {
  //         label: 'Watchlist',
  //         field: 'watchlist',
  //         tdClass: 'text-center-all',
  //         width: '80px'
  //       },

  //       {
  //         label: 'First',
  //         field: 'firstName',
  //       },
  //       {
  //         label: 'Last',
  //         field: 'lastName',
  //       },
  //       {
  //         label: 'Phone',
  //         field: 'phone',
  //       },
  //       {
  //         label: 'Email',
  //         field: 'email',
  //       },
  //       // {
  //       //   label: 'Groups',
  //       //   field: 'addtogroup',
  //       //   width: '200px'
  //       // },
  //       {
  //         label: 'Points',
  //         field: 'points',
  //       },
  //       {
  //         label: 'Stars',
  //         field: 'rating',
  //       },
  //       {
  //         label: 'Skills',
  //         field: 'skills',
  //       },
  //       {
  //         label: 'City',
  //         field: 'address.city',
  //       },
  //       {
  //         label: 'State',
  //         field: 'address.state',
  //       },
  //     ]
  //   };
  // },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'userWatchlist']),
    sortedList() {
      return this.userWatchlist.sort((a, b) => a.firstName.localeCompare(b.firstName))
    },
  },
  components: {
    Loader,
    UserTable
  },
  created () {
    this.$store.dispatch("getUserWatchlist")
  },
  methods: {
    // isWatchList(row) {
    //   if (this.userProfile && this.userProfile.userWatchList && this.userProfile.userWatchList.includes(row.id)) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // async removeWatch(row) {
    //   row.isWatchList = false
    //   await this.$store.dispatch('unWatchUser', row.id)
    // },
    // formatDate(q) {
    //   if(q) {
    //     const postedDate = new Date(q.seconds) * 1000;
    //     return moment(postedDate).format('MMMM Do YYYY')
    //   } else {
    //     return null
    //   }
    // },
  },
  beforeDestroy() {
    this.$store.dispatch("clearUserWatchlist")
  }
}
</script>